import React from 'react';
import PropTypes from 'prop-types';

import {Column, Container, Row} from '../../../../layout/Grid';
import {ReactComponent as LightboxImage} from '../../../../../assets/images/trainings/img-overlay--punkte.svg';
import Headline from '../../../../components/Headline';
import Paragraph from '../../../../components/Paragraph';
import Button from '../../../../components/Button';
import notificationLang from '../../../../../lang/notification.lang';
import trainingsLang from '../../../../../lang/pages/trainings.lang';

const TrainingCompletedMessage = ({onClose, inLightbox, buttonText}) => {
    return (
        <Container width="narrow" className="text-center">
            <Row>
                <Column xs={12}>
                    <LightboxImage />
                    <Headline type={inLightbox ? 'h5' : 'h1'}>
                        {notificationLang.training.finish.success.hl}
                    </Headline>
                    <Paragraph size="small" margin="0">
                        {notificationLang.training.finish.success.msg(50)}
                    </Paragraph>
                </Column>
                <Column xs={12}>
                    <Button
                        label={
                            buttonText || trainingsLang.successLightboxButton
                        }
                        onClick={onClose}
                    />
                </Column>
            </Row>
        </Container>
    );
};

TrainingCompletedMessage.propTypes = {
    onClose: PropTypes.func,
    inLightbox: PropTypes.bool,
    buttonText: PropTypes.string,
};

TrainingCompletedMessage.defaultProps = {
    onClose: () => {},
    inLightbox: true,
    buttonText: trainingsLang.successLightboxButton,
};

export default TrainingCompletedMessage;
