import React, {useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';

import {
    start,
    closeSuccessLightbox,
    selectIsScormReady,
    selectCurrent,
} from '../../../../model/training';
import {Iframe} from './styles';
import Lightbox from '../../../components/Lightbox';
import Image from '../../../components/Image';
import {Section, Container} from '../../../layout/Grid';
import TrainingCompletedMessage from './TrainingCompletedMessage';
import ComingSoonImg from '../../../../assets/images/trainings/img-coming-soon-training--platzhalter.png';
import LocationContext from '../../../utils/LocationContext';
import {routes} from '../../../../model/navigation';

export default function Training({id, url, isReleased}) {
    const location = useContext(LocationContext);
    const isScormReady = useSelector(selectIsScormReady);
    const {isSuccessLightboxOpen} = useSelector(selectCurrent);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(start(id));
    }, [dispatch, id]);

    const isFromAcademy = location?.search?.includes(routes.academy);

    const handleOnClose = () => {
        if (isFromAcademy) {
            navigate(routes.academy);
        } else {
            dispatch(closeSuccessLightbox());
        }
    };

    return typeof window === 'undefined' || isReleased ? (
        <>
            {isScormReady && <Iframe title="Training" id={id} src={url} />}
            <Lightbox
                maxWidth="25rem"
                isOpen={isSuccessLightboxOpen}
                onClose={handleOnClose}>
                <TrainingCompletedMessage
                    onClose={handleOnClose}
                    buttonText={isFromAcademy && 'Zur Akademie'}
                />
            </Lightbox>
        </>
    ) : (
        <Section>
            <Container width="medium">
                <Image
                    src={ComingSoonImg}
                    alt="Niedrigdetail-Zeichnung einer Apothekerin mit Banner im Hintergrund"
                    title="Dein Training erscheint bald!"
                    width="100%"
                    height="auto"
                    responsive
                />
            </Container>
        </Section>
    );
}

Training.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isReleased: PropTypes.bool.isRequired,
};
