import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import Layout from '../layout/Layout';
import {Container, Section, Row, Column} from '../layout/Grid';
import Headline from '../components/Headline';
import Paragraph from '../components/Paragraph';
import Button from '../components/Button';
import Header from '../layout/Header';
import Training from '../domains/training/Training';
import ShopWindow from '../layout/ShopWindow';
import TrainingTeaser from '../domains/training/Teaser';
import MultiStepPersonalization from '../domains/profile/MultiStepPersonalization';
import PointLimitMessage from '../domains/point-limit/PointLimitMessage';
import {routes} from '../../model/navigation';
import {
    selectById as selectTrainingById,
    suggest as suggestTrainings,
} from '../../model/training';
import lang from '../../lang/pages/trainings.lang';
import profileLang from '../../lang/pages/profil.lang';
import {makeUrl} from '../../model/backend-api';
import {selectIsLoggedIn} from '../../model/authentication';
import {
    selectUserCategories,
    selectIsPersonalizationFinished,
    selectHasUserReachedPointLimit,
} from '../../model/profile';
import Lightbox from '../components/Lightbox';
import Divider from '../components/Divider';
import {color} from '../../styles/variables';
import {parseHtmlToReact} from '../../utils/html-react-parser';

// NOTE: no page query needed because static training data is being sourced into the
// redux store, see gatsby-ssr.js, gatsby-browser.js and SourceStaticTrainings.js

export default function TrainingPage({pageContext}) {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userCategories = useSelector(selectUserCategories);
    const isPersonalizationFinished = useSelector(
        selectIsPersonalizationFinished
    );
    const hasUserReachedPointLimit = useSelector(
        selectHasUserReachedPointLimit
    );
    const id = +pageContext.id; // make it a number
    const trainingById = useSelector(selectTrainingById);
    const training = trainingById[id];
    const {
        title,
        description,
        image,
        iframeUrl,
        instruction,
        id: trainingId,
        isReleased,
    } = training;
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const {
        numTrainingsMatchingInterest,
        numTrainingsNotMatchingInterest,
        suggestedTrainings,
    } = suggestTrainings(trainingById, isLoggedIn, userCategories, trainingId);
    return (
        <>
            <Layout>
                <Header
                    photo
                    backBtnLink={routes.trainings}
                    img={makeUrl(image)}>
                    <Headline type="h1">
                        <strong>{title}</strong>
                    </Headline>
                    <Paragraph margin="0 0 2rem 0">{description}</Paragraph>
                    <Button
                        secondary
                        label="Anleitung"
                        icon="info"
                        onClick={() => setLightboxOpen(true)}
                    />
                </Header>
                {hasUserReachedPointLimit ? (
                    <PointLimitMessage />
                ) : (
                    <Training id={id} url={iframeUrl} isReleased={isReleased} />
                )}
                <Section theme="blue">
                    <Container width="wide" className="text-center">
                        <Row>
                            <Column md={8} lg={6} offset={{md: 2, lg: 3}}>
                                {isPersonalizationFinished ? (
                                    <>
                                        <Headline type="h3">
                                            {lang.schaufensterHeadline}
                                        </Headline>
                                        <Paragraph size="small">
                                            {lang.schaufensterParagraph(
                                                numTrainingsMatchingInterest,
                                                numTrainingsNotMatchingInterest
                                            )}
                                        </Paragraph>
                                    </>
                                ) : (
                                    <>
                                        <Headline type="h3">
                                            {
                                                profileLang.personalisierung
                                                    .headline
                                            }
                                        </Headline>
                                        <Paragraph size="small">
                                            {
                                                profileLang.personalisierung
                                                    .paragraph
                                            }
                                        </Paragraph>
                                    </>
                                )}
                            </Column>
                        </Row>
                        <Row>
                            <Column xs={12}>
                                <Divider dashed color={color.white} />
                            </Column>
                        </Row>
                    </Container>
                    <Container width="wide">
                        {isPersonalizationFinished ? (
                            <ShopWindow teasers={suggestedTrainings}>
                                {(training) => (
                                    <TrainingTeaser
                                        title={training.title}
                                        type={training.type.name}
                                        image={training.image}
                                        points={training.points}
                                        duration={training.duration}
                                        completed={training.completed}
                                        route={training.route}
                                        isReleased={training.isReleased}
                                    />
                                )}
                            </ShopWindow>
                        ) : (
                            <MultiStepPersonalization />
                        )}
                    </Container>
                </Section>
                <Lightbox
                    maxWidth="30rem"
                    isOpen={lightboxOpen}
                    showPadding
                    onClose={() => setLightboxOpen(false)}>
                    <Container>
                        <Headline type="h5" hideTopMargin>
                            <strong>Anleitung</strong>
                        </Headline>
                        {parseHtmlToReact(instruction)}
                    </Container>
                </Lightbox>
            </Layout>
        </>
    );
}

TrainingPage.propTypes = {
    pageContext: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
